/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "MAINTENANCE_MODE": false,
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "mail",
            "endpoint": `https://developer-api.lexconnect.cloud/mail`,
            "region": "us-east-1"
        },
        {
            "name": "google",
            "endpoint": `https://developer-api.lexconnect.cloud/google`,
            "region": "us-east-1"
        },
        {
            "name": "switch",
            "endpoint": `https://developer-api.lexconnect.cloud/switch`,
            "region": "us-east-1"
        },
        {
            "name": "switchPivots",
            "endpoint": `https://developer-pivot.lexconnect.cloud/switchPivots`,
            "region": "us-east-1"
        },
        {
            "name": "bandwidth",
            "endpoint": `https://developer-api.lexconnect.cloud/bandwidth`,
            "region": "us-east-1"
        },
        {
            "name": "apiMappings",
            "endpoint": `https://developer-api.lexconnect.cloud/api`,
            "region": "us-east-1"
        },
        {
            "name": "frontend",
            "endpoint": `https://developer-api.lexconnect.cloud/frontend`,
            "region": "us-east-1"
        },
        {
            "name": "calix",
            "endpoint": `https://developer-api.lexconnect.cloud/calix`,
            "region": "us-east-1"
        },
        {
            "name": "adtran",
            "endpoint": `https://developer-api.lexconnect.cloud/adtran`,
            "region": "us-east-1"
        },
        {
            "name": "psql",
            "endpoint": "https://developer-api.lexconnect.cloud/psql",
            "region": "us-east-1"
        },
        {
            "name": "user_auth",
            "endpoint": "https://developer-api.lexconnect.cloud/user_auth_v2",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://u6yplc7quncf5bftsbsqdoruru.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_appsync_apiKey": "da2-htbiwyn2xndynffzgsseagcmy4",
    "aws_cognito_identity_pool_id": "us-east-1:fba9b804-dcee-4d0a-a2f3-50c042494ddc",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_JjyzY60Qe",
    "aws_user_pools_web_client_id": "1bm84eee4o78mchdi460m38g2t",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "ON",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "lex-connect-site-uploads180545-developer",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
