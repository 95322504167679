<template>
    <v-app>
        <v-app-bar height="50" v-if="USER_IS_LOGGED_IN && INITIAL_APP_LOAD">
            <navBar :userName="username_for_nav.toUpperCase()" :showPhonePopoutProp="showPhonePopup"
                @close-clickPhoneIcon="handlePhonePopout" :showPhoneIconProp="showPhoneIcon"
                :hasUnreadNotifications="false" @logout-user="handleSignOut" />
        </v-app-bar>

        <v-navigation-drawer style="background: #2e3e91;" expand-on-hover rail permanent
            v-if="USER_IS_LOGGED_IN && INITIAL_APP_LOAD">
            <Navigation />
        </v-navigation-drawer>

        <v-main v-if="INITIAL_APP_LOAD" style="background-color: #e2f1f4">
            <router-view :key="$route.path" />
            <AlertsComponent style="z-index: 10000;" />
        </v-main>
    </v-app>
</template>


<style>
::-webkit-scrollbar {
    width: 10px;
    height: 10px !important;
}

::-webkit-scrollbar-track-piece {
    background-color: #ffffff
}

:root {
    --easy-table-scrollbar-thumb-color: #b1cdea !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #b1cdea;
    border: 3px solid #fff;
}

.vue3-easy-data-table__main[data-v-19cc4b1b]::-webkit-scrollbar-track {
    border-radius: 0px !important;
}

.vue3-easy-data-table__main[data-v-19cc4b1b]::-webkit-scrollbar-thumb {
    border-radius: 0px !important;
}

header.v-toolbar {
    overflow: visible;
}

html {
    overflow-y: auto !important;
}

body {
    font-family: "Red Hat Display", sans-serif;
}

.vue3-easy-data-table__main {
    overflow: revert !important;
}

.vgl-item:not(.vgl-item--placeholder) {
    border: 0px !important;
    filter: drop-shadow(1px 1px 1px rgba(39, 29, 122, 0.1));
}
</style>


<script setup>
/* eslint-disable */

const hostname = window.location.hostname;
// const last_mouse_move = ref(Date.now());

var ENVIRONMENT = "";

import { onMounted, watch, ref, computed } from "vue";
import navBar from "@/components/base_components/Navigation/NavBar.vue";
import Navigation from "@/components/base_components/Navigation/MenuBar.vue";
import { router } from "@/router/index.js";
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import { useAuthStore } from "@/stores/auth/authStore.js";
import { useWebRTCStore } from "./stores/webRTC/webRTCStore";
import { useAgentStore } from "@/stores/agentStore.js";
import { onBeforeUnmount, onUnmounted } from "vue";
import { useGeneralStore } from "@/stores/generalStore.js";
import { useAlertsStore } from "@/stores/alertsStore.js";
import AlertsComponent from "@/components/base_components/AlertsComponent.vue";
import { API } from 'aws-amplify';

import { useQubicleRealtimeStore } from "./stores/ContactCenter/Management/QubicleRealtimeStore";

const generalStore = useGeneralStore();
const alertsStore = useAlertsStore();

if (awsconfig.aws_user_files_s3_bucket.includes("developer")) {
    generalStore.setRTFlag(false);
    ENVIRONMENT = "developer-";
    generalStore.setEnvironment("DEVELOPMENT");
}

var config = awsconfig;
Amplify.configure(config);



const USER_POOL_ID = awsconfig.aws_user_pools_id.split("_")[1];
const CLIENT_ID = awsconfig.aws_user_pools_web_client_id;

var defaultPath = ref("/");
var INITIAL_APP_LOAD = ref(false);
var showPhonePopup = ref(false);
var showPhoneIcon = ref(false);
const username_for_nav = ref(null);
var WARNING_TIMER = null;
var TIMEOUT_NOW_TIMER = null;
var AGENT_SOCKET_TIMER = null;
var refreshThrottled = ref(false);




const authStore = useAuthStore();



const webRTCStore = useWebRTCStore();
const agentStore = useAgentStore();
const qubicleStore = useQubicleRealtimeStore();



authStore.setCurrentEnvironment(ENVIRONMENT);

const USER_IS_LOGGED_IN = computed(() => {
    return authStore.getUserAuthStatus;

})



async function refreshTokens() {
    if (USER_IS_LOGGED_IN === false) { return; }

    if (!refreshThrottled.value) {
        try {
            refreshThrottled.value = true;
            const SESSION_COOKIE = checkIfSessionCookieAlive();

            if (SESSION_COOKIE) {
                const TIME_OUT_NOW = authStore.getTimeOutMinutes;
                const last_auth_user = localStorage.getItem(`${btoa(USER_POOL_ID)}.lastAuthUser`);
                const device_key = localStorage.getItem(`${btoa(last_auth_user)}.${btoa(USER_POOL_ID)}.deviceKey`);

                const refresh_auth_response = await refreshAuthUsingRefreshToken(device_key);

                if (refresh_auth_response.success && refresh_auth_response.data.AuthenticationResult) {
                    const access_token = refresh_auth_response.data.AuthenticationResult.AccessToken;
                    const id_token =  refresh_auth_response.data.AuthenticationResult.IdToken;
                    authStore.updateAccessAndIdTokens(access_token, id_token, last_auth_user, USER_POOL_ID);
                }
                else {
                    handleSignOut();
                }

                setSessionCookie("session", TIME_OUT_NOW);
                resetSessionTimers();
            }
            else {
                handleSignOut();
            }
        } catch (e) {
            //Do Nothing
        }

        setTimeout(() => {
            refreshThrottled.value = false;
        }, 10000);
    }
}

async function checkRtHealth() {
    await generalStore.checkRTHealth();
}

function startSessionTimers() {
    const TIME_OUT_NOW = authStore.getTimeOutMinutes;
    const TIME_OUT_WARNING = authStore.getTimeOutWarningMinutes;

    WARNING_TIMER = setTimeout(sendTimeOutWarning, TIME_OUT_WARNING * 60000);
    TIMEOUT_NOW_TIMER = setTimeout(timeOutUser, TIME_OUT_NOW * 60000);
}

function resetSessionTimers() {
    if (USER_IS_LOGGED_IN.value === true) {
        clearTimeout(WARNING_TIMER);
        clearTimeout(TIMEOUT_NOW_TIMER);
        startSessionTimers();
    }
}

function sendTimeOutWarning() {
    const TIME_OUT_NOW = authStore.getTimeOutMinutes;
    const TIME_OUT_WARNING = authStore.getTimeOutWarningMinutes;
    const time_left = TIME_OUT_NOW - TIME_OUT_WARNING;

    alertsStore.addAlert("Notification", `Inactive page. you will be logged out in ${time_left} minutes.`);
}

function timeOutUser() {
    const SESSION_COOKIE = checkIfSessionCookieAlive();

    if (!SESSION_COOKIE) {
        handleSignOut();
    }
    else {
        resetSessionTimers();
    }
}



function setSessionCookie(cname, exminutes) {
    const d = new Date();
    d.setTime(d.getTime() + (exminutes * 60 * 1000));
    document.cookie = `${cname}=${d.getTime()}; max-age=${exminutes * 60}; path=/`;
}

function getSessionCookie(cname) {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return null;
}

function checkIfSessionCookieAlive() {
    const SESSION_COOKIE = getSessionCookie("session");
    return SESSION_COOKIE;
}

async function handleSignOut() {
    document.removeEventListener('mousedown', refreshTokens);
    document.removeEventListener('mousemove', refreshTokens);
    document.removeEventListener('keypress', refreshTokens);
    qubicleStore.closeSocket();
    agentStore.closeAgentSocket();
    clearTimeout(AGENT_SOCKET_TIMER);

    try {
        const sign_out_response = await API.post("user_auth", '/sign_out', {
            headers: {
                'Authorization': `Bearer ${authStore.$state.cognito_tokens.id_token}`,
                'Content-Type': 'application/json'
            },
            body: {
                access_token: authStore.$state.cognito_tokens.access_token,
            },
        });


        console.log("sign_out_response: ", sign_out_response);

        authStore.removeCognitoTokens(USER_POOL_ID);

        await authStore.setUserAuthStatus(false);
        router.replace("/");
    }
    catch(error) {
        console.log(error);
    }
}

const handlePhonePopout = async (inputVal) => {
    showPhonePopup.value = !inputVal;
}


async function startAgentUserStatus(agentStore) {

    await agentStore.getCurrentUserRecipientID();

    const current_agent_id = agentStore.getCurrentAgentID;

    if (current_agent_id) {
        AGENT_SOCKET_TIMER = setInterval(async () => {
            agentStore.checkWebsocketHealth();
            const current_socket_status = agentStore.getAgentSocketStatus;

            if (current_socket_status === 3) {
                agentStore.initializeSwitchSocket();
            }
        }, 5000);

        await agentStore.initializeSwitchSocket();
    }

}

async function webRTCCheck(authStore, webRTCStore) {
    if (authStore.getCurrentUser && authStore.getCurrentUser.username) {
        await webRTCStore.connectToWebRTC_phone(authStore.getCurrentUser.username);

        let webphone = webRTCStore.getLibWebPhone;

        if (webphone) {
            webphone.getUserAgent().start();
            showPhoneIcon.value = true;

            // TODO: this needs to stay for debugging purposes. uncomment if you need to debug.
            // webphone.onAny((event, ...data) => console.log(event, data));

            webphone.on("call.created", (lwp, call) => {
                console.log("call created");
                console.log(call);
                webRTCStore.setCurrentCallType("Regular");

                let formatted_date_time = new Date().toLocaleString().replace(/(.*) (\d+:\d+:\d+)/, '$1 - $2').replace(",", "");
                let direction = call.getDirection();
                let identity = call.remoteIdentity(true);

                if (direction == "terminating") {

                    // set the webRTCStore values
                    webRTCStore.setCurrentCallCallerID(identity._uri._user);
                    webRTCStore.setCurrentCallCallerName(identity.display_name);
                    webRTCStore.addUpdateCallHistory(call._id, "incoming", formatted_date_time, identity.display_name, identity._uri._user);

                    //open up the phonepopout and migrate to the active page
                    if (!showPhonePopup.value) {
                        showPhonePopup.value = true;
                    }

                    webRTCStore.changeSelectedPage("incoming");
                }
                else {
                    webRTCStore.setCurrentCallCallerID(identity._uri._user);
                    webRTCStore.setCurrentCallCallerName(identity.display_name);
                    webRTCStore.setCurrentCallCallDuration(0);
                    //open up the phonepopout and migrate to the active page
                    if (!showPhonePopup.value) {
                        showPhonePopup.value = true;
                    }
                    webRTCStore.addUpdateCallHistory(call._id, "outgoing", formatted_date_time, identity.display_name, identity._uri._user);
                    webRTCStore.changeSelectedPage("active");
                }


                console.log("Current Call: ", webRTCStore.getCurrentCall);
                webRTCStore.setNewCallCallObj(call);
            });

            webphone.on("call.primary.timeupdate", (lwp, call, date_time, not_used, time) => {
                webRTCStore.setCurrentCallCallDuration(time);
                let call_history = webRTCStore.getCallHistory;
                let call_history_obj = call_history[call._id];
                webRTCStore.addUpdateCallHistory(call_history_obj.id, call_history_obj.direction, call_history_obj.date_time, call_history_obj.display_name, call_history_obj.number, time);
            });

            webphone.on("call.ended", (lwp, call, message) => {
                console.log(message)
                console.log(lwp);
                var sessions = lwp.getUserAgent()._userAgent._sessions;
                webRTCStore.setCurrentActiveSessions(sessions);
                console.log("Current Active Sessions:", webRTCStore.getCurrentActiveSessions);

                if (!webRTCStore.getCallsOnHold[call._id]) {
                    webRTCStore.setCurrentCallStatus("");
                    webRTCStore.changeSelectedPage("Keypad");
                    webRTCStore.clearMute();
                }
                if (webRTCStore.getCallsOnHold[call._id]) {
                    webRTCStore.removeCallOnHold(call._id);
                }
            });

            webphone.on("call.failed", (lwp, call, message) => {
                let call_history = webRTCStore.getCallHistory;
                let call_history_obj = call_history[call._id];
                let missed = false;

                if (call_history_obj.direction == "incoming") {
                    missed = true;
                }

                if (message.cause == "Rejected") {
                    if (!webRTCStore.getCallsOnHold[call._id]) {
                        webRTCStore.setCurrentCallStatus("");
                        webRTCStore.changeSelectedPage("Keypad");
                    }
                    webRTCStore.addUpdateCallHistory(call_history_obj.id, call_history_obj.direction, call_history_obj.date_time, call_history_obj.display_name, call_history_obj.number, call_history_obj.duration, missed);
                }

                if (message.cause == "Canceled") {
                    if (!webRTCStore.getCallsOnHold[call._id]) {
                        webRTCStore.setCurrentCallStatus("");
                        webRTCStore.changeSelectedPage("Keypad");
                    }
                    webRTCStore.addUpdateCallHistory(call_history_obj.id, call_history_obj.direction, call_history_obj.date_time, call_history_obj.display_name, call_history_obj.number, call_history_obj.duration, missed);
                }

                if (webRTCStore.getCallsOnHold[call._id]) {
                    webRTCStore.removeCallOnHold(call._id);
                    // TODO: need to figure out how to make the CallsOnHoldComp.vue update its list
                }
            });

            webphone.on("call.established", (lwp, call, message) => {
                console.log("call established");
                console.log(message);
                console.log(call);


                var sessions = lwp.getUserAgent()._userAgent._sessions;
                console.log(sessions);
                webRTCStore.setCurrentActiveSessions(sessions);
                console.log("Current Active Sessions:", webRTCStore.getCurrentActiveSessions);


                webRTCStore.setCurrentCallCallObj(call);
                webRTCStore.setCurrentCallStatus("active");
                let identity = call.remoteIdentity(true);

                if (identity._uri._user != "*98") {
                    webRTCStore.changeSelectedPage("active");
                }


            });

            webphone.on("call.hold", (lwp, call, message) => {
                console.log(message);
                // call._clearPrimary();

                let call_history = webRTCStore.getCallHistory;
                let call_history_obj = call_history[call._id];

                let direction = call.getDirection();
                if (direction == "terminating") {
                    direction = "incoming";
                }
                else {
                    direction = "outgoing";
                }

                webRTCStore.addUpdateCallsOnHold(call_history_obj.id, call_history_obj.display_name, call_history_obj.direction, call_history_obj.date_time, call_history_obj.number, call_history_obj.duration, call);
                webRTCStore.setCurrentCallStatus("");
                // webRTCStore.setCurrentCallCallObj(null);
                webRTCStore.changeSelectedPage("Calls On Hold");
            });
        }
    }
}



async function setupUserOnLogin() {
    try {
        authStore.setUserFilesBucket(awsconfig.aws_user_files_s3_bucket);

        const get_current_user_response = await API.post("user_auth", '/get_current_user', {
            headers: {
                'Authorization': `Bearer ${authStore.$state.cognito_tokens.id_token}`,
                'Content-Type': 'application/json'
            },
            body: {
                access_token: authStore.$state.cognito_tokens.access_token,
            },
        });


        if (get_current_user_response.success) {
            await authStore.storeUserInfo(get_current_user_response.data);
        }
        else {
            throw new Error("User not Authenticated");
        }

        username_for_nav.value = authStore.$state.email;

        await Promise.all([
            await webRTCCheck(authStore, webRTCStore), //Setup WebRTC
            await authStore.setCurrentUser(), //Sets User in Auth Store for Use.
            await authStore.fetchUsersGroups(),
            await authStore.fetchSessionInformation(),
            await authStore.setDefaultApplicationPath(),
            await startAgentUserStatus(agentStore),
            await checkRtHealth(),
            await authStore.setLastLoggedInForUser(authStore.$state.email)
        ]);

        const TIME_OUT_NOW = authStore.getTimeOutMinutes;
        setSessionCookie("session", TIME_OUT_NOW);
        startSessionTimers();
        document.addEventListener('mousedown', refreshTokens);
        document.addEventListener('mousemove', refreshTokens);
        document.addEventListener('keypress', refreshTokens);

        defaultPath = await authStore.getDefaultApplicationPath;

        var foundRedirectPath = false;

        for (let location of authStore.getToLocationArray) {
            if (location.path != "/") {
                router.replace(location);
                foundRedirectPath = true;
                break;
            }
        }

        if (!foundRedirectPath) {
            router.replace({ path: defaultPath });
        }
    }
    catch(error) {
        router.replace("/");
        INITIAL_APP_LOAD.value = true;
    }
}

async function refreshAuthUsingRefreshToken(device_key) {
    const refresh_auth_response = await API.post("user_auth", '/refresh_auth', {
        headers: {
            'Authorization': `Bearer ${authStore.$state.cognito_tokens.id_token}`,
            'Content-Type': 'application/json'
        },
        body: {
            refresh_token: authStore.$state.cognito_tokens.refresh_token,
            client_id: CLIENT_ID,
            device_key: device_key
        },
    });

    return refresh_auth_response;
}

function configureGroupSpecificTasks() {
    const found_madison = authStore.$state.users_groups.findIndex(group => group.Name == "Madison Call Center");
 
    if (found_madison != -1) { //Madison Specific Code to not require user to be active to keep session alive.
        setInterval(async () => {
            const last_auth_user = localStorage.getItem(`${btoa(USER_POOL_ID)}.lastAuthUser`);
            const device_key = localStorage.getItem(`${btoa(last_auth_user)}.${btoa(USER_POOL_ID)}.deviceKey`);
            const refresh_auth_response = await refreshAuthUsingRefreshToken(device_key);

            if (refresh_auth_response.success && refresh_auth_response.data.AuthenticationResult) {
                const access_token = refresh_auth_response.data.AuthenticationResult.AccessToken;
                const id_token =  refresh_auth_response.data.AuthenticationResult.IdToken;
                authStore.updateAccessAndIdTokens(access_token, id_token, last_auth_user, USER_POOL_ID);
            }
            else {
                handleSignOut();
            }
        }, 1_800_000); //Every 30 Minutes
    }
}



// End of Functions

// Life Cycle Hooks
onMounted(async () => { //Check If Session is still alive when page is refreshed.
    INITIAL_APP_LOAD.value = false;

    try {
        const last_auth_user = localStorage.getItem(`${btoa(USER_POOL_ID)}.lastAuthUser`);

        authStore.initializeCognitoTokens(last_auth_user, USER_POOL_ID);

        const get_current_user_response = await API.post("user_auth", '/get_current_user', {
            headers: {
                'Authorization': `Bearer ${authStore.$state.cognito_tokens.id_token}`,
                'Content-Type': 'application/json'
            },
            body: {
                access_token: authStore.$state.cognito_tokens.access_token,
            },
        });


        if (get_current_user_response.success && get_current_user_response.data.UserMFASettingList) {
            await authStore.setUserAuthStatus(true);
        }
        else {
            await authStore.setUserAuthStatus(false);
            router.replace("/");
            INITIAL_APP_LOAD.value = true;
        }
    }
    catch(error) {
        await authStore.setUserAuthStatus(false);
        router.replace("/");
        INITIAL_APP_LOAD.value = true;
    }
});


onBeforeUnmount(() => {
    document.removeEventListener('mousedown', refreshTokens);
    document.removeEventListener('mousemove', refreshTokens);
    document.removeEventListener('keypress', refreshTokens);
    qubicleStore.closeSocket();
    agentStore.closeAgentSocket();
    clearTimeout(AGENT_SOCKET_TIMER);
});



watch(USER_IS_LOGGED_IN, async (new_value) => {
    if (new_value === true) {
        INITIAL_APP_LOAD.value = false;
        await setupUserOnLogin();
        configureGroupSpecificTasks()
        INITIAL_APP_LOAD.value = true;


        await qubicleStore.init();
    }
});
</script>
