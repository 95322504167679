<template>
    <div>
        <label v-if="PROPS.required" :class="label_size_class">{{ PROPS.label }} *</label>
        <label v-else :class="label_size_class">{{ PROPS.label }}</label>

        <v-tooltip v-if="PROPS.renderPasswordTooltip && PROPS.password_tooltip_content" v-model="showTooltip">
            <template v-slot:activator="{ props }">
            <span style="margin-left: 1%;" v-bind="props"><img width="15" src="@/assets/icons/question.png" alt=""></span>
          </template>
          <span>Password Requirements:</span><br>
          <span v-html="PROPS.password_tooltip_content"></span>
        </v-tooltip>
    </div>
    <div style="display: flex; width: 100%;">
        <input 
            :type="input_type" 
            :disabled="PROPS.disabled"
            :autocomplete="autocomplete"
            @input="handleInputChange"
            :value="PROPS.modelValue" :class="input_size_class" />
        <span v-if="PROPS.type == 'password'" :class="append_size_class" @click="showPassword"></span>
    </div>
</template>



<script setup>
import { defineProps, defineEmits, ref, computed } from 'vue';

const PROPS = defineProps({
    label: {
        type: String,
        default: ""
    },
    disabled: { //Disable Input from being interactable
        type: Boolean,
        default: false
    },
    required: { //Add Required Visual to Inputs Label
        type: Boolean,
        default: false
    },
    size: {
        type: String,
        default: 'default'
    },
    type: {
        type: String,
        default: "text"
    },
    autocomplete: {
        type: String,
        default: ""
    },
    renderPasswordTooltip: {
        type: Boolean,
        default: false
    },
    password_tooltip_content: {
        type: String,
        default: null
    },
    modelValue: String
});

//Consts
const input_type = ref(PROPS.type);
const passwordToggle = ref(true);
const showTooltip = ref(false);



//Emits
const emit = defineEmits(["update:modelValue"]);


//Computed
const input_size_class = computed(() => {
    let withAppend = false;

    if (PROPS.type == 'password') {
        withAppend = true;
    }


    if (PROPS.size == 'large') {
        if (withAppend) {
            return 'largeSizeAppended';
        }
        else {
            return 'largeSize';
        }
    }
    else {
        if (withAppend) {
            return 'defaultSizeAppended';
        }
        else {
            return 'defaultSize';
        }
    }
});

const append_size_class = computed(() => {
    if (passwordToggle.value) {
        if (PROPS.size == 'large') {
            return 'appendIconShowLarge';
        }
        else {
            return 'appendIconShow';
        }
    }
    else {
        if (PROPS.size == 'large') {
            return 'appendIconHideLarge';
        }
        else {
            return 'appendIconHideDefault';
        }
    }
});

const label_size_class = computed(() => {

    if (PROPS.size == 'large') {
        return 'labelLarge';
    }
    else {
        return 'labelDefault';
    }


});



//Functions
function handleInputChange(event) {
    emit("update:modelValue", event.target.value);
}

function showPassword() {
    if (input_type.value == 'password') {
        input_type.value = 'text';
        passwordToggle.value = false;
    }
    else {
        input_type.value = 'password';
        passwordToggle.value = true;
    }
}
</script>





<style scoped>
.defaultSize {
    outline: #2e3e91 solid 0.25pt;
    border-radius: 2px;
    padding-left: 8px;
    padding-top: 2px;
    padding-bottom: 2px;
    font-size: 9pt;
    font-weight: 500;
    color: #271d7a;
    background-color: #ffffff;
    height: 28px;
    width: 100%;
}

.defaultSizeAppended {
    outline: #2e3e91 solid 0.25pt;
    border-radius: 2px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    padding-left: 8px;
    padding-top: 2px;
    padding-bottom: 2px;
    font-size: 9pt;
    font-weight: 500;
    color: #271d7a;
    background-color: #ffffff;
    height: 28px;
    width: 90%;
}

.largeSize {
    outline: #2e3e91 solid 0.25pt;
    border-radius: 2px;
    padding-left: 8px;
    padding-top: 2px;
    padding-bottom: 2px;
    font-size: 12pt;
    font-weight: 500;
    color: #271d7a;
    background-color: #ffffff;
    height: 42px;
    width: 100%;
}

.largeSizeAppended {
    outline: #2e3e91 solid 0.25pt;
    border-radius: 2px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    padding-left: 8px;
    padding-top: 2px;
    padding-bottom: 2px;
    font-size: 12pt;
    font-weight: 500;
    color: #271d7a;
    background-color: #ffffff;
    height: 42px;
    width: 90%;
}


.defaultSizeAppended:disabled, .defaultSize:disabled {
  background-color: #eff4f9;
}

.appendIconShowDefault { 
    background-color: #fff;
    height: 28px;
    position: relative;
    display: inline-block;
    padding-left: 8px;
    border-radius: 2px;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
    padding-bottom: 2px;
    outline: #2e3e91 solid 0.25pt;
    padding-top: 2px;
    width: 10%;
    background: url("@/assets/icons/eye-solid.png") no-repeat center;
    background-size: 18px;
    cursor: pointer;
}

.appendIconShowLarge {
    background-color: #fff;
    height: 42px;
    position: relative;
    display: inline-block;
    padding-left: 8px;
    border-radius: 2px;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
    padding-bottom: 2px;
    outline: #2e3e91 solid 0.25pt;
    padding-top: 2px;
    width: 10%;
    background: url("@/assets/icons/eye-solid.png") no-repeat center;
    background-size: 18px;
    cursor: pointer;
}

.appendIconHideDefault {
    background-color: #fff;
    height: 28px;
    position: relative;
    display: inline-block;
    padding-left: 8px;
    border-radius: 2px;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
    padding-bottom: 2px;
    outline: #2e3e91 solid 0.25pt;
    padding-top: 2px;
    width: 10%;
    background: url("@/assets/icons/eye-hide.png") no-repeat center;
    background-size: 18px;
    cursor: pointer;
}

.appendIconHideLarge {
    background-color: #fff;
    height: 42px;
    position: relative;
    display: inline-block;
    padding-left: 8px;
    border-radius: 2px;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
    padding-bottom: 2px;
    outline: #2e3e91 solid 0.25pt;
    padding-top: 2px;
    width: 10%;
    background: url("@/assets/icons/eye-hide.png") no-repeat center;
    background-size: 18px;
    cursor: pointer;
}

.labelDefault {
    font-size: 9pt;
    font-weight: 600;
    color: #271d7a;
    padding-bottom: 5px;
}

.labelLarge {
    font-size: 25px;
    font-weight: 500;
    color: #271d7a;
    padding-bottom: 5px;
}

</style>